body {
    width: 800px;
    max-width: 80vw;
    margin: 0 auto;
}

header {
    width: 100%;
    padding: 20px;
}

.desktop-only * {
    display: none;
}

.button, .button * {
    max-width: 80vw;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search {
    display: flex;
    align-items: flex-start
}

.entry-compact {
    width: 120px;
    height: 125px;
    margin: 10px 5px;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: scroll;
}

@media screen and (min-width: 500px) {
    .entry-compact {
        width: 150px;
        height: 100px;
        margin: 15px;
        font-size: initial;
    }
}

.entry-ultra-compact {
    height: 75px;
}

.display-container {
    padding: 0;
}

@media screen and (min-width: 500px) {
    .entry-compact {
        width: 150px;
        height: 100px;
        margin: 15px;
        font-size: initial;
    }

    .search {
        display: inline;
    }

    .mobile-only * {
        display: none;
    }

    .desktop-only * {
        display: initial;
    }
}